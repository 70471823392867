import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import { color, media } from '../components/Styles'
import { connect } from 'react-redux'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { setCart } from "../state/app"
import Button2 from "../components/Button"
import { graphql } from "gatsby"

//SVG
import CloseIcon from '../images/header-footer/close.svg'


import FormCheckout from "../components/formulario/FormCheckout"
import { useTranslation } from "react-i18next"
import Layout from "../components/Layout"
import Seo from "../components/Seo"



const Header = ({ cart, dispatch, data }) => {
    const { t } = useTranslation()

    // REMOVE PRODUCT FROM CART

    const [updateCart, setUpdateCart] = useState(cart);

    useEffect(() => {
        dispatch(setCart(updateCart))
    }, [updateCart])

    var stringToHTML = function (str) {
        var dom = document.createElement('div');
        dom.innerHTML = str;
        return dom;
    };

    return (
        <Layout>
            <Seo title="ENCOMENDAS | ENOMAC" />
            <ProductEmptyStyled>
                {cart.length === 0 &&
                    <div className="wrapper-general" >
                        <h4>{t("products", { returnObjects: true }).question.title}</h4>
                        <Button2
                            text={t("products", { returnObjects: true }).question.subtitle}
                            to={"/"}
                        />
                    </div>
                }
            </ProductEmptyStyled>
            <ProductsStyled>
                {cart.length >= 1 &&
                    <div className="title">
                        <h1>Lista de Pedidos</h1>
                    </div>
                }
                <div className='containerr'>
                    <div className='image-container'>
                        {cart.map((product, i) => {
                            const image = {
                                image: getImage(product.image?.localFile),
                                alt: product.image?.altText || '',
                            }
                            return (
                                <div className='product' key={i}>
                                    <CloseIcon
                                        className='remove-product'
                                        onClick={() => setUpdateCart(updateCart.filter(deleteProduct => deleteProduct.id !== product.id))}
                                    />
                                    {image?.image &&
                                        <GatsbyImage image={image.image} alt={image.alt || ''} className='product-image' />
                                    }
                                    <div className='product-info'>
                                        <div className="product-name">
                                            <h2><b>{product.name}</b></h2>
                                            <h4>{stringToHTML(product.shortDescription).getElementsByClassName("title")[0].innerHTML}</h4>
                                        </div>

                                    </div>
                                    <div className="product-campo">
                                        <span className='product-quantity'>Uni. : {product.quantity}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    {cart.length >= 1 &&
                        <section className='content' id="anchor">
                            <FormCheckout data={t("contacts", { returnObjects: true })} />
                        </section>
                    }
                </div>
            </ProductsStyled>

        </Layout>

    )
}

export default connect(state => ({
    cart: state.app.cart
}), null)(Header)

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "products", "services", "store", "contacts"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`

const ProductEmptyStyled = styled.div`
.wrapper-general {
    margin: 4rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h4 {
        text-align: center; 
        font-size: 26px;
    }
}
`


const ProductsStyled = styled.div`
    position:relative;

    .title {
        text-align: center;
        padding: 5%;
    }

    .containerr{
      max-width:1920px;
      margin:0 auto;
      color:${color.black};
      display:grid;
      grid-template-columns:0.5fr 2fr;
      gap: 5%;
      padding: 0 5rem;

        ${media.l`
            grid-template-columns:1fr;
            gap:2rem;
            padding: 0 1rem;
        `}

        .image-container{
            position:relative;
            width: 700px;

            ${media.s`
                width: auto;
            `}
            
            .product {
                display: flex;
                align-items: center;
                gap: 8%;
                justify-content: space-between;
                border-top: 1px solid black;
                padding: 2%;

                ${media.s`
                    padding: 4% 0%;
                    gap: 1%;
                `}
            }

            .gatsby-image-wrapper.product-image {
                width: 100px;

                ${media.s`
                    width: 50px; 
                    height: 50px;
                `}
            }

            .product-info {
                display: flex;
                justify-content: center;
                align-items: baseline;
                min-width: 260px;

                ${media.s`
                    min-width: 150px;
                    font-size: 7px;
                `}

                .product-name {
                    max-width: 177px;
                    text-align: center;
                    font-size: 0.8rem;

                    h2 {
                        line-height: 1.5rem;
                    }
                }
            }

            svg.remove-product {
                width: 20px;
            }

            .product-campo {
                display: flex;
                align-items: center;
            }
        }

        .content{
            display:flex;
            flex-direction:column;
            justify-content:center;
            gap:3rem;

            ${media.l`
                gap:2rem;
            `}

            
        }

    }
`