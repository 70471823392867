import React, { useState, useRef } from "react"
import styled from 'styled-components'
import { color, media } from '../Styles'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from 'react-bootstrap/Alert';
import { connect } from 'react-redux'


const axios = require("axios")

const Form = ({ data, cart }) => {
    const form = useRef()
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [checked, setChecked] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true)


    var checker = []

    function checkValues() {
        checker = []
        let checkerTemp = []
        if (typeof document !== 'undefined') {

            if (!document.querySelector("#name").value) {
                checkerTemp.push(" " + data.form.placeholders[0])
            }

            if (!document.querySelector("#email").value) {
                checkerTemp.push(" " + data.form.placeholders[2])
            }
            if (document.querySelector("#email").value) {
                if (!document.querySelector("#email").value.toLowerCase()
                    .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    )) {
                    checkerTemp.push(" " + data.form.placeholders[2])
                }
            }
            if (!document.querySelector("#company").value) {
                checkerTemp.push(" " + data.form.placeholders[1])
            }
            if (!document.querySelector("#contact").value) {
                checkerTemp.push(" " + data.form.placeholders[3])
            }
            if (!document.querySelector("#message").value) {
                checkerTemp.push(" " + data.form.placeholders[4])
            }
        }

        if (!checked) {
            if (checkerTemp.length < 1) {
                checkerTemp.push(data.form.terms.single)
            } else {
                checkerTemp.push(data.form.terms.plural)
            }
        }

        checker = checkerTemp
    }

    const formSubmit = (e) => {
        checkValues()
        if (checker.length < 1) {
            e.preventDefault()
            const formData = new FormData(form.current)

            formData.append('name', document.querySelector("#name").value)
            formData.append('email', document.querySelector("#email").value)
            formData.append('company', document.querySelector("#company").value)
            formData.append('contact', document.querySelector("#contact").value)
            formData.append('message', document.querySelector("#message").value)
            formData.append("checkout", true)

            // Adicione cada item do array ao FormData
            cart.forEach((item, index) => {
                formData.append(`items[${index}][name]`, item.name);
                formData.append(`items[${index}][quantity]`, item.quantity);
            });

            axios
                .post("https://teste2.invisual.pt/form-contact.php", formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data; charset=UTF-8",
                    },
                })
                .then(() => {
                    if (typeof window !== "undefined") {
                        //window.gtag("event", 'Enviado', {
                        //event_category: "Formulário de Contacto",
                        //event_label: "Lead Contacto"
                        //})
                    }
                    setSuccess(true)
                    form.current.reset()
                })


        } else {
            e.preventDefault()
            setError("" + checker + ".")
        }

    }

    return (
        <FormStyled>
            <div className='container'>
                <h2>{data.form.title}</h2>
                <form ref={form} onSubmit={formSubmit}>
                    <div className='input-box' >
                        <input id='name' type='text' name={data.form.placeholders[0]} placeholder=' ' required />
                        <label htmlFor='name'>{data.form.placeholders[0]} *</label>
                    </div>
                    <div className='input-box'>
                        <input id='company' type='text' name={data.form.placeholders[1]} placeholder=' ' required />
                        <label htmlFor='empresa'>{data.form.placeholders[1]} *</label>
                    </div>
                    <div className='input-box' >
                        <input id='email' type='email' name={data.form.placeholders[2]} placeholder=' ' required />
                        <label htmlFor='email'>{data.form.placeholders[2]} *</label>
                    </div>
                    <div className='input-box' >
                        <input id='contact' type='number' name={data.form.placeholders[3]} placeholder=' ' />
                        <label htmlFor='contact'>{data.form.placeholders[3]}</label>
                    </div>
                    <div className='input-box' >
                        <label htmlFor='message'>{data.form.placeholders[4]} *</label>
                        <textarea id='message' name={data.form.placeholders[4]} placeholder=' ' rows="8" required style={{ width: "99%", border: "1px solid #F2AF00" }} />
                    </div>

                    <div className='input-box' id='info'>
                        <span ><b >{data.form.mandatory}</b></span><br /><br />
                        <span>{data.form.info}</span>
                    </div>

                    <button className='btn' type="submit" id='button' onClick={handleShow}>
                        <span>{data.form.btntext}</span>
                    </button>

                    {success &&
                        <Alert key='success' variant='success' show={show} onHide={handleClose} >
                            {data.form.sucess.warning}
                        </Alert>
                    }
                    {error &&
                        <Alert key='warning' variant='warning' show={show} onHide={handleClose} >
                            {data.form.error.text}
                        </Alert>

                    }
                    <div className="check">
                        <FormControlLabel
                            className="IMedium grey info mt-1"
                            control={<Checkbox sx={{
                                color: "#F2AF00",
                                '&.Mui-checked': {
                                    color: "#F2AF00",
                                },
                            }}
                                checked={checked}
                                onChange={() => setChecked(!checked)} />}
                            label={data.form.textterms}
                        />
                    </div>

                </form>
            </div>
        </FormStyled>
    )
}

export default connect(state => ({
    cart: state.app.cart
}), null)(Form)


const FormStyled = styled.div`
    background-color:#ebebeb;

    .container{
        max-width:1920px;
        margin:0 auto;  
        padding:clamp(30px,15vw,80px) 5%;
        color:${color.black};

        .check {
        }
    }  

    h2{
        max-width:1000px;
        margin:0 auto;
        text-transform:uppercase;
        font-weight:500;
        font-size: 1.8rem;
        letter-spacing: 0.2rem;
        margin-bottom:3rem;
    }
    
    form{
        display:grid;
        grid-template-areas: 'name' 'empresa' 'email' 'contact' 'message' 'info' 'check' 'button' ;
            grid-template-columns: 1fr;
        grid-gap: 3rem;
        max-width:1000px;
        margin:0 auto;

        ${media.m`
            grid-template-areas: 'name' 'empresa' 'email' 'contact' 'message' 'info' 'check' 'button' ;
            grid-template-columns: 1fr;
        `}

        #name{
            grid-area:name;
        }
        #empresa{
            grid-area:empresa;
        }
        #email{
            grid-area:email;
        }
        #contact{
            grid-area:contact;
        }
        #message{
            grid-area:message;
            width: 100%;
        }
        #info{
            grid-area:info;
        }
        #button{
            grid-area:button;
        }

        .input-box{
            position:relative;

            input:focus + label,
            input:focus + label,
            input:not(:placeholder-shown) + label {
                top: -15px;
                left: 0;
                font-size:1rem;
            }

            label,
            input {
                transition: all 0.3s ease;
            }

            label {
                pointer-events: none;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 3%;
                z-index: 2;
            }

            input {
                position: relative;
                z-index: 1;
                padding: 0 3%;
                height: 50px;
                border:1px solid ${color.yellow};
                outline: none;
                background-color:${color.white};
                width:100%;
                box-sizing:border-box;
            }

            label, input{
                text-transform:uppercase;
                font-size:1.2rem;
                color:${color.black};
                font-weight: 300;
            }
        }

        #message{
            label{
                position:static;
            }
            
            textarea {
                position: relative;
                z-index: 1;
                padding: 1rem;
                border:1px solid ${color.yellow};
                outline: none;
                background-color:${color.white};
                width:100%;
                box-sizing:border-box;
                margin-top:1rem;
                text-transform:uppercase;
                font-size:1.2rem;
                color:${color.black};
                font-weight: 300;
            }
        }

        #info{
            span{
                font-size:0.8rem;
            }
        }

        .btn{
            display: block;
            width:fit-content;
            text-decoration: none;
            color: ${color.white};
            background: ${color.yellow};
            padding: 1.5rem clamp(15px, 5vw, 35px);
            letter-spacing: 1px;
            text-align: center;
            position: relative;
            transition: all .35s;
            cursor:pointer;
            font-weight:600;
            text-transform:uppercase;
            border:none;
            height:fit-content;
            margin-left:auto;

            ${media.m`
                width:100%;
            `}

            span{
                position: relative;
                white-space:nowrap;

                ${media.s`
                    white-space: break-spaces;
                `}
            } 
            
            :hover {
                background-color: #000;
                color: ${color.yellow};
            }
    }
    }
`